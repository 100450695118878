// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bramy-ogrodzeniowe-przemyslowe-garazowe-js": () => import("./../src/pages/bramy-ogrodzeniowe-przemyslowe-garazowe.js" /* webpackChunkName: "component---src-pages-bramy-ogrodzeniowe-przemyslowe-garazowe-js" */),
  "component---src-pages-drzwi-wewnetrzne-i-zewnetrzne-js": () => import("./../src/pages/drzwi-wewnetrzne-i-zewnetrzne.js" /* webpackChunkName: "component---src-pages-drzwi-wewnetrzne-i-zewnetrzne-js" */),
  "component---src-pages-galeria-js": () => import("./../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-napedy-do-bram-js": () => import("./../src/pages/napedy-do-bram.js" /* webpackChunkName: "component---src-pages-napedy-do-bram-js" */),
  "component---src-pages-okna-pcv-i-alu-js": () => import("./../src/pages/okna-pcv-i-alu.js" /* webpackChunkName: "component---src-pages-okna-pcv-i-alu-js" */),
  "component---src-pages-piloty-i-sterowanie-radiowe-js": () => import("./../src/pages/piloty-i-sterowanie-radiowe.js" /* webpackChunkName: "component---src-pages-piloty-i-sterowanie-radiowe-js" */),
  "component---src-pages-rolety-zewnetrzne-markizy-moskitiery-js": () => import("./../src/pages/rolety-zewnetrzne-markizy-moskitiery.js" /* webpackChunkName: "component---src-pages-rolety-zewnetrzne-markizy-moskitiery-js" */),
  "component---src-pages-serwis-js": () => import("./../src/pages/serwis.js" /* webpackChunkName: "component---src-pages-serwis-js" */),
  "component---src-pages-szlabany-blokady-parkingowe-js": () => import("./../src/pages/szlabany-blokady-parkingowe.js" /* webpackChunkName: "component---src-pages-szlabany-blokady-parkingowe-js" */)
}

